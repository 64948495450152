.shop .category {
  padding: 30px;
  height: 60vh;
}

.chead h1 {
  font-size: 20px;
  margin-bottom: 20px;
}

.chead h1:nth-child(2) {
  opacity: 0.5;
  border-left: 3px solid rgba(0, 0, 0, 0.5);
  padding-left: 70px;
}

.shop .category .box {
  margin: 15px 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  padding: 5px 10px;
}

.shop .category img {
  width: auto;
  height: auto;
}

.shop .category .box2 {
  margin: 40px;
  text-align: center;
}

.shop .category button {
  padding: 10px;
  background: none;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  border: none;
}

.shop .contentWidth {
  margin-left: 30px;
}

.grid1 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.shop .product img {
  width: 100%;
}

@media (max-width: 712px) {
  .grid1 {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
  .shop .category {
    display: none;
  }
}
