.home {
  margin-top: 20px;
}

.homeSlide .box img {
  width: 100%;
  height: 50vh;
  -o-object-fit: fill;
     object-fit: fill;
  margin-top: 10px;
}

.slick-dots {
  bottom: -50px !important;
}

@media (max-width: 712px) {
  .homeSlide .box img {
    height: 22vh;
  }
}
