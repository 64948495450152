.topCat {
  position: relative;
}

.topCat .box {
  margin: 0 20px;
}

.topCat img {
  width: 100%;
  height: 100%;
}

.topCat .nametop {
  position: relative;
  width: 100%;
}

.topCat .nametop span {
  background-color: var(--blue);
  color: var(--primary-colour);
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 50px;
  margin: 5px;
  position: absolute;
  top: 0;
}

.topCat .nametop .tleft {
  left: 0;
}

.topCat .nametop .tright {
  background-color: aliceblue;
  color: black;
  position: absolute;
  right: 0;
}

.topCat .img {
  height: 136px;
}

.topCat .img img {
  -o-object-fit: cover;
     object-fit: cover;
}
