.cart-items {
  background: #f6f9fc;
  height: auto;
  padding: 140px 0;
}

.cart-items .cart-details {
  width: 70%;
}

.cart-items .cart-total {
  width: 30%;
  margin-top: 30px;
  margin-left: 30px;
  height: 130px;
}

.cart-items .img {
  width: 150px;
  height: 150px;
}

.cart-items img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.cart-items .cart-details h1 {
  margin-top: 30px;
}

.cart-items .cart-details h3 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}

.cart-items .cart-details h4 {
  font-size: 15px;
  font-weight: 400;
  margin-top: 50px;
  color: var(--grey);
}

.cart-items .cart-details h4 span {
  color: #e94560;
  margin-left: 20px;
  font-weight: 500;
}

.cart-items .cart-list {
  background: var(--primary-colour);
  margin-top: 30px;
}

.cart-items .removeCart {
  background: none;
  font-size: 25px;
  text-align: right;
  margin-right: 10px;
}

.cart-items .cartControl {
  margin-top: 50px;
}

.cart-items .cartControl button {
  width: 40px;
  height: 40px;
  margin: 10px;
  border-radius: 5px;
  font-size: 20px;
}

.cart-items .inCart {
  background-color: none;
}

.cart-items .decCart {
  background: #f6f9fc;
}

.cart-items .cart-total h4 {
  font-size: 20px;
  font-weight: 400;
}

.cart-items .cart-total h3 {
  font-size: 20px;
  font-weight: 500;
  color: #e94560;
}

.cart-items .cart-total h2 {
  font-size: 18px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb;
}
@media (max-width: 712px) {
  .cart-items .cart-details{
      width: 100%;
      font-size: 14px;
  }
  .cart-items .cart-total{
      width: 83%;
  }
  .cart-items{
    padding: 60px 0;
  }
}
