.navlink .nav li {
  margin-left: 2rem;
  font-weight: bold;
}

.search {
  margin: 20px 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: -webkit-sticky !important;
  position: sticky !important;
}

.search .search-bar {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.3fr 3fr 0.7fr;
      grid-template-columns: 1.3fr 3fr 0.7fr;
}

.search .logo {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
}

.search .logo h1 {
  font-size: 2rem;
}

.search .search-box {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/3;
  height: 45px;
  border: 1px solid var(--colour);
  border-radius: 4rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.search .search-box .sicon {
  margin: 12px;
  font-size: 20px;
  opacity: 0.5;
  font-weight: bold;
  color: var(--colour);
}

.search .search-box input {
  width: 80%;
  padding: 10px;
  border: none;
  outline: none;
}

.search .search-box ::-webkit-input-placeholder {
  font-size: 18px;
}

.search .search-box :-ms-input-placeholder {
  font-size: 18px;
}

.search .search-box ::-ms-input-placeholder {
  font-size: 18px;
}

.search .search-box ::placeholder {
  font-size: 18px;
}

.search .search-box span {
  width: 20%;
  opacity: 0.5;
  border-left: 2px solid rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-bottom-right-radius: 4rem;
  border-top-right-radius: 4rem;
}

.search .cart {
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.search .cart span {
  position: absolute;
  top: 0px;
  right: 15px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  text-align: center;
  background-color: red;
  font-size: 10px;
  color: var(--primary-colour);
}

.search .picon {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-column: 3/4;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.search .picon .carticon {
  width: 30px;
  height: 30px;
  text-align: center;
  margin: 0 20px;
  color: var(--colour);
  cursor: pointer;
}

.search .f-flex {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.header {
  -webkit-box-shadow: -1px 15px 17px -8px rgba(0, 0, 0, 0.2);
          box-shadow: -1px 15px 17px -8px rgba(0, 0, 0, 0.2);
}

.header ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header ul li a {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 600;
}

.header ul li a:hover {
  color: var(--hover);
}

.hamburger-menu {
  display: none;
}

@media (max-width: 1350px) {
  .header ul li a {
    font-size: 13px;
  }
  .search .logo h1 {
    font-size: 30px;
  }
  .search .search-box {
    height: 40px;
  }
  .search .search-box .sicon {
    margin: auto;
  }
  .search .search-box input {
    width: 55%;
    margin: auto;
  }
  .search .search-box span {
    width: auto;
    margin: auto;
    padding: 0;
    font-size: 13px;
    font-weight: bold;
  }
  .hamburger-menu {
    display: none;
  }
}

@media (max-width: 1000px) {
  .hidden {
    display: none;
  }
  .header .nav {
    position: absolute;
    top: 12rem;
    right: 5%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 90%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    background-color: var(--main-heading);
    text-align: center;
    -webkit-box-shadow: 1px 15px 17px 8px rgba(0, 0, 0, 0.2);
            box-shadow: 1px 15px 17px 8px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  .header .nav li {
    margin: 5px auto;
    padding: 5px 0;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: all 1.5s ease;
    transition: all 1.5s ease;
    cursor: pointer;
  }
  .header .nav li a {
    margin: auto;
    font-size: 15px;
  }
  .header .nav li a:hover {
    color: var(--hover);
  }
  .header .nav li:hover {
    background-color: var(--grey-colour);
  }
  .search .logo h1 {
    font-size: 25px;
  }
  .search .picon .carticon {
    margin: 10px 10px;
  }
  .search .cart span {
    top: 1px;
    right: 5px;
  }
  .hamburger-menu {
    display: block;
    font-size: 30px;
    cursor: pointer;
  }
}

@media (max-width: 850px) {
  .search {
    margin: 10px 0;
  }
  .search-box {
    display: none;
    width: 0%;
    height: 0%;
  }
}
