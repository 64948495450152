.cart-items{
    background: #f6f9fc;
    height: auto;
    padding: 110px 0;
    .cart-details{
        width: 70%;
    }
    .cart-total{
        width: 30%;
        margin-top: 30px;
        margin-left: 30px;
        height: 130px;
    }
    .img{
        width: 150px;
        height: 150px;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .cart-details{
        h3{
            font-size: 20px;
            font-weight: 500;
            margin-top: 20px;
        }
        h4{
            font-size: 15px;
            font-weight: 400;
            margin-top: 50px;
            color: var(--grey);
            span{
                color: #e94560;
                margin-left: 20px;
                font-weight: 500;
            }
        }
    }
    .cart-list{
        background: var(--primary-colour);
        margin-top: 30px ;
    }
    .removeCart{
        background: none;
        font-size: 25px;
        text-align: right;
        margin-right: 10px;
    }
    .cartControl{
        margin-top: 50px;
        button{
            width:  40px;
            height: 40px;
            margin: 10px;
            border-radius: 5px;
            font-size: 20px;
        }
    }
    .inCart{
        background-color: none;
    }
    .decCart{
        background: #f6f9fc;
    }
    .cart-total{
        h4{
            font-size: 20px;
            font-weight: 400;
        }
        h3{
            font-size: 20px;
            font-weight: 500;
            color: #e94560;
        }
        h2{
            font-size: 18px;
            margin-bottom: 20px;
            border-bottom: 1px solid rgb(3 0 71 / 9%);
            padding-bottom: 10px;
            color: #e94560;
        }
    }
}

@media (max-width: 712px) {
    .cart-items .cart-details{
        width: 100%;
        font-size: 14px;
    }
    .cart-items .cart-total{
        width: 83%;
    }
}