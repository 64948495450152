.heading{
    img{
        width: 100px;
        height: 100px;
    }
}
.newarrivals{

    h4{
        font-weight: 400;
    }
    span{
        color: red;
        font-size: 15px;
        font-weight: 500;
    }
    .box{
        img{
           width: 100%; 
           height: 100%;
        }
    }
}


@media screen {
    
}